export const netWorthByAge: Record<string, Record<string, number>> = {
  '0': {
    '10': -6_900,
    '20': -600,
    '30': 100,
    '40': 2_000,
    '50': 5_000,
    '60': 8_900,
    '70': 16_200,
    '80': 31_300,
    '90': 71_300,
  },
  '30': {
    '10': -6_200,
    '20': 0,
    '30': 2_000,
    '40': 8_100,
    '50': 17_800,
    '60': 32_900,
    '70': 58_700,
    '80': 107_100,
    '90': 202_200,
  },
  '35': {
    '10': -1_800,
    '20': 1_600,
    '30': 9_300,
    '40': 21_800,
    '50': 45_800,
    '60': 79_300,
    '70': 122_500,
    '80': 192_500,
    '90': 312_900,
  },
  '40': {
    '10': 0,
    '20': 5_300,
    '30': 19_800,
    '40': 46_200,
    '50': 87_200,
    '60': 133_200,
    '70': 201_400,
    '80': 283_100,
    '90': 438_900,
  },
  '45': {
    '10': 0,
    '20': 5_200,
    '30': 23_400,
    '40': 56_400,
    '50': 105_000,
    '60': 166_100,
    '70': 245_200,
    '80': 337_900,
    '90': 519_000,
  },
  '50': {
    '10': 0,
    '20': 5_900,
    '30': 24_300,
    '40': 62_100,
    '50': 115_100,
    '60': 178_800,
    '70': 254_200,
    '80': 359_600,
    '90': 539_200,
  },
  '55': {
    '10': 0,
    '20': 4_200,
    '30': 23_500,
    '40': 64_000,
    '50': 121_900,
    '60': 187_200,
    '70': 268_700,
    '80': 396_900,
    '90': 625_400,
  },
  '60': {
    '10': 0,
    '20': 4_500,
    '30': 24_200,
    '40': 65_200,
    '50': 120_500,
    '60': 183_500,
    '70': 267_700,
    '80': 385_900,
    '90': 600_800,
  },
  '65': {
    '10': 0,
    '20': 4_200,
    '30': 23_900,
    '40': 61_000,
    '50': 117_400,
    '60': 179_400,
    '70': 257_300,
    '80': 365_500,
    '90': 581_800,
  },
  '70': {
    '10': 0,
    '20': 6_900,
    '30': 26_400,
    '40': 72_700,
    '50': 129_600,
    '60': 192_100,
    '70': 272_000,
    '80': 375_600,
    '90': 575_600,
  },
  '75': {
    '10': 800,
    '20': 9_200,
    '30': 25_700,
    '40': 59_900,
    '50': 112_500,
    '60': 176_900,
    '70': 250_200,
    '80': 346_900,
    '90': 517_700,
  },
};
export const netWorth: Record<string, number> = {
  '5': -3_000,
  '10': 0,
  '15': 600,
  '20': 2_400,
  '25': 5_400,
  '30': 10_700,
  '35': 16_500,
  '40': 27_100,
  '45': 42_300,
  '50': 60_400,
  '55': 84_300,
  '60': 111_900,
  '65': 139_300,
  '70': 174_900,
  '75': 226_700,
  '80': 274_700,
  '85': 357_800,
  '90': 468_000,
  '95': 722_000,
  '99': 1_071_825,
};

export function getPercentileNetWorth(userNetWorth: number, age: number|false = false): number {
  let datum = netWorth;
  if (age !== false) {
    let ageGroup: number = age - (age % 5);
    while (!(ageGroup in netWorthByAge) || ageGroup < 0) {
      ageGroup -= 5;
      if (ageGroup < 0) {
        ageGroup = 0;
      }
    }
    datum = netWorthByAge[ageGroup];
  }
  for (const datumKey of Object.keys(datum).reverse()) {
    if (userNetWorth > datum[datumKey]) {
      return parseInt(datumKey, 10);
    }
  }
  return 0;
}
