

























import {Component, Prop, Vue} from 'vue-property-decorator';
import {getPercentileNetWorth, netWorthByAge} from "@/data/netWorth";

@Component
export default class NetWorthDisplay extends Vue {
  @Prop({type: Boolean})
  readonly byAge!: boolean;

  get age() {
    const birthday = (this as any).localStorage.birthday;
    if (!birthday.match(/[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/)) {
      return 0;
    }
    const b = birthday.split(/\D/);
    const date = new Date(b[0], --b[1], b[2]);
    const ageInMs = new Date().getTime() - date.getTime();
    const ageDate = new Date(ageInMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  get ageGroup() {
    let ageGroup: number = this.age - (this.age % 5);
    while (!(ageGroup in netWorthByAge) || ageGroup < 0) {
      ageGroup -= 5;
      if (ageGroup < 0) {
        ageGroup = 0;
      }
    }
    switch (ageGroup) {
      case 0:
        return '0-30';
      case 75:
        return '75+';
    }
    return ageGroup + '-' + (ageGroup + 5);
  }

  get netWorthPercentage(): number {
    return getPercentileNetWorth(this.localStorage.netWorth, this.byAge ? this.age : false);
  }
}
