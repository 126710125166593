export const netIncomeMonthlyByAge: Record<string, Record<string, number>> = {
  '0': {
    '0': 166.9,
    '1': 386.9167,
    '2': 443.1945,
    '3': 497.2223,
    '4': 549.3095,
    '5': 594.4445,
    '6': 639.5833,
    '7': 670.6667,
    '8': 721.3492,
    '9': 763.4167,
    '10': 792.9666,
    '11': 826.9827,
    '12': 865.7222,
    '13': 886.5278,
    '14': 905.5556,
    '15': 926.6667,
    '16': 941.3333,
    '17': 969.6667,
    '18': 990.7813,
    '19': 1008.333,
    '20': 1025.111,
    '21': 1043.125,
    '22': 1064.271,
    '23': 1087.473,
    '24': 1113.333,
    '25': 1133.796,
    '26': 1156.909,
    '27': 1174.514,
    '28': 1198.021,
    '29': 1218.056,
    '30': 1241.075,
    '31': 1261.24,
    '32': 1286.27,
    '33': 1302.805,
    '34': 1322.396,
    '35': 1340.472,
    '36': 1354.058,
    '37': 1374.667,
    '38': 1395.625,
    '39': 1419.708,
    '40': 1440.397,
    '41': 1459.472,
    '42': 1480.391,
    '43': 1500.609,
    '44': 1522.153,
    '45': 1540.185,
    '46': 1558.691,
    '47': 1584.583,
    '48': 1596.984,
    '49': 1618.567,
    '50': 1637.176,
    '51': 1660.0,
    '52': 1682.292,
    '53': 1715.29,
    '54': 1730.469,
    '55': 1746.812,
    '56': 1770.476,
    '57': 1793.056,
    '58': 1807.659,
    '59': 1832.79,
    '60': 1855.185,
    '61': 1889.931,
    '62': 1911.726,
    '63': 1938.889,
    '64': 1960.754,
    '65': 1978.102,
    '66': 2009.762,
    '67': 2049.41,
    '68': 2067.963,
    '69': 2089.549,
    '70': 2113.75,
    '71': 2145.521,
    '72': 2171.542,
    '73': 2205.476,
    '74': 2228.587,
    '75': 2266.778,
    '76': 2286.905,
    '77': 2327.5,
    '78': 2354.688,
    '79': 2398.186,
    '80': 2455.992,
    '81': 2497.315,
    '82': 2537.958,
    '83': 2595.957,
    '84': 2662.619,
    '85': 2702.064,
    '86': 2755.873,
    '87': 2831.667,
    '88': 2894.491,
    '89': 2956.833,
    '90': 3029.542,
    '91': 3123.587,
    '92': 3208.704,
    '93': 3331.865,
    '94': 3460.333,
    '95': 3689.583,
    '96': 3923.492,
    '97': 4335.417,
    '98': 4940.083,
    '99': 7550.542,
  },
  '25': {
    '0': 288.8889,
    '1': 436.6667,
    '2': 515.2381,
    '3': 596.6667,
    '4': 662.1212,
    '5': 710.0,
    '6': 759.8333,
    '7': 799.75,
    '8': 853.3889,
    '9': 886.5278,
    '10': 917.3016,
    '11': 946.0833,
    '12': 973.75,
    '13': 1003.254,
    '14': 1027.5,
    '15': 1055.0,
    '16': 1096.232,
    '17': 1127.907,
    '18': 1156.795,
    '19': 1183.843,
    '20': 1217.593,
    '21': 1245.635,
    '22': 1270.778,
    '23': 1289.127,
    '24': 1306.944,
    '25': 1333.532,
    '26': 1351.917,
    '27': 1380.278,
    '28': 1401.405,
    '29': 1428.889,
    '30': 1449.083,
    '31': 1475.75,
    '32': 1495.833,
    '33': 1525.873,
    '34': 1541.792,
    '35': 1562.708,
    '36': 1587.0,
    '37': 1606.065,
    '38': 1627.5,
    '39': 1655.917,
    '40': 1671.865,
    '41': 1699.542,
    '42': 1723.406,
    '43': 1742.536,
    '44': 1758.056,
    '45': 1781.786,
    '46': 1805.631,
    '47': 1828.278,
    '48': 1848.75,
    '49': 1877.037,
    '50': 1906.417,
    '51': 1931.0,
    '52': 1954.667,
    '53': 1971.25,
    '54': 1999.881,
    '55': 2016.417,
    '56': 2043.583,
    '57': 2068.135,
    '58': 2092.267,
    '59': 2120.611,
    '60': 2144.167,
    '61': 2168.722,
    '62': 2190.119,
    '63': 2212.917,
    '64': 2241.667,
    '65': 2270.88,
    '66': 2292.421,
    '67': 2328.133,
    '68': 2361.417,
    '69': 2395.318,
    '70': 2424.167,
    '71': 2473.778,
    '72': 2505.278,
    '73': 2528.333,
    '74': 2557.222,
    '75': 2605.917,
    '76': 2642.421,
    '77': 2690.093,
    '78': 2717.833,
    '79': 2756.5,
    '80': 2799.056,
    '81': 2838.917,
    '82': 2876.339,
    '83': 2917.167,
    '84': 2950.333,
    '85': 3005.25,
    '86': 3072.583,
    '87': 3123.587,
    '88': 3203.889,
    '89': 3302.731,
    '90': 3407.111,
    '91': 3520.333,
    '92': 3645.833,
    '93': 3846.667,
    '94': 4020.88,
    '95': 4193.167,
    '96': 4597.963,
    '97': 4926.22,
    '98': 5586.583,
    '99': 7615.037,

  },
  '50': {
    '0': 392.1667,
    '1': 560.1667,
    '2': 674.1,
    '3': 741.2222,
    '4': 798.5,
    '5': 848.3333,
    '6': 898.7222,
    '7': 941.3333,
    '8': 995.6667,
    '9': 1050.444,
    '10': 1095.333,
    '11': 1146.0,
    '12': 1182.5,
    '13': 1210.778,
    '14': 1240.0,
    '15': 1276.306,
    '16': 1297.417,
    '17': 1333.167,
    '18': 1362.611,
    '19': 1408.083,
    '20': 1431.0,
    '21': 1455.067,
    '22': 1487.25,
    '23': 1530.5,
    '24': 1564.758,
    '25': 1588.333,
    '26': 1607.333,
    '27': 1631.5,
    '28': 1666.667,
    '29': 1699.167,
    '30': 1733.917,
    '31': 1758.5,
    '32': 1788.567,
    '33': 1804.8,
    '34': 1822.583,
    '35': 1846.278,
    '36': 1871.833,
    '37': 1895.056,
    '38': 1916.196,
    '39': 1955.708,
    '40': 1978.5,
    '41': 2011.625,
    '42': 2030.543,
    '43': 2049.435,
    '44': 2079.367,
    '45': 2105.0,
    '46': 2127.222,
    '47': 2150.0,
    '48': 2170.445,
    '49': 2209.945,
    '50': 2228.111,
    '51': 2250.722,
    '52': 2279.333,
    '53': 2299.667,
    '54': 2330.458,
    '55': 2357.833,
    '56': 2393.278,
    '57': 2416.055,
    '58': 2440.867,
    '59': 2463.3,
    '60': 2488.111,
    '61': 2515.0,
    '62': 2542.833,
    '63': 2579.537,
    '64': 2616.133,
    '65': 2654.083,
    '66': 2705.167,
    '67': 2744.0,
    '68': 2781.8,
    '69': 2809.074,
    '70': 2848.849,
    '71': 2889.944,
    '72': 2926.611,
    '73': 2976.0,
    '74': 3013.222,
    '75': 3053.056,
    '76': 3082.733,
    '77': 3143.25,
    '78': 3204.213,
    '79': 3263.833,
    '80': 3341.292,
    '81': 3386.333,
    '82': 3442.056,
    '83': 3513.444,
    '84': 3573.444,
    '85': 3641.75,
    '86': 3755.5,
    '87': 3832.833,
    '88': 3923.492,
    '89': 4073.833,
    '90': 4163.056,
    '91': 4299.208,
    '92': 4461.333,
    '93': 4610.667,
    '94': 4861.167,
    '95': 5161.944,
    '96': 5602.611,
    '97': 6083.0,
    '98': 7549.278,
    '99': 10827.11,
  },
  '65': {
    '0': 553.9445,
    '1': 670.8333,
    '2': 751.9167,
    '3': 798.0833,
    '4': 845.8333,
    '5': 880.6667,
    '6': 903.5833,
    '7': 946.4167,
    '8': 996.5833,
    '9': 1028.389,
    '10': 1057.667,
    '11': 1089.833,
    '12': 1125.583,
    '13': 1151.75,
    '14': 1168.167,
    '15': 1187.417,
    '16': 1210.778,
    '17': 1237.389,
    '18': 1252.583,
    '19': 1267.333,
    '20': 1283.778,
    '21': 1305.667,
    '22': 1329.667,
    '23': 1347.722,
    '24': 1362.056,
    '25': 1379.333,
    '26': 1389.958,
    '27': 1406.278,
    '28': 1428.5,
    '29': 1443.583,
    '30': 1458.778,
    '31': 1469.556,
    '32': 1489.222,
    '33': 1508.167,
    '34': 1528.444,
    '35': 1547.556,
    '36': 1565.167,
    '37': 1585.167,
    '38': 1603.0,
    '39': 1617.333,
    '40': 1640.5,
    '41': 1657.5,
    '42': 1678.778,
    '43': 1694.833,
    '44': 1710.583,
    '45': 1722.5,
    '46': 1740.389,
    '47': 1755.833,
    '48': 1777.444,
    '49': 1793.917,
    '50': 1804.556,
    '51': 1829.417,
    '52': 1848.833,
    '53': 1865.944,
    '54': 1890.722,
    '55': 1907.083,
    '56': 1928.667,
    '57': 1950.889,
    '58': 1965.444,
    '59': 1982.056,
    '60': 1999.25,
    '61': 2028.917,
    '62': 2053.889,
    '63': 2075.917,
    '64': 2101.445,
    '65': 2122.167,
    '66': 2145.417,
    '67': 2171.583,
    '68': 2208.0,
    '69': 2248.625,
    '70': 2272.333,
    '71': 2295.222,
    '72': 2323.167,
    '73': 2367.667,
    '74': 2394.111,
    '75': 2421.445,
    '76': 2461.0,
    '77': 2499.917,
    '78': 2552.778,
    '79': 2594.0,
    '80': 2632.417,
    '81': 2698.25,
    '82': 2726.445,
    '83': 2785.278,
    '84': 2826.722,
    '85': 2887.333,
    '86': 2944.389,
    '87': 3035.111,
    '88': 3141.056,
    '89': 3238.0,
    '90': 3341.444,
    '91': 3480.667,
    '92': 3618.833,
    '93': 3739.944,
    '94': 3936.167,
    '95': 4145.833,
    '96': 4413.167,
    '97': 4681.556,
    '98': 5372.389,
    '99': 6743.333,

  },
};
export const netIncomeMonthly: Record<string, number> = {
  '0': 300.4167,
  '1': 457.1429,
  '2': 550.4762,
  '3': 636.4815,
  '4': 687.5641,
  '5': 750.3572,
  '6': 792.8206,
  '7': 839.8333,
  '8': 874.0942,
  '9': 902.2778,
  '10': 932.2222,
  '11': 965.3969,
  '12': 995.3333,
  '13': 1024.921,
  '14': 1051.167,
  '15': 1085.0,
  '16': 1117.143,
  '17': 1148.667,
  '18': 1168.958,
  '19': 1197.611,
  '20': 1222.889,
  '21': 1248.135,
  '22': 1271.5,
  '23': 1291.583,
  '24': 1310.917,
  '25': 1337.417,
  '26': 1354.867,
  '27': 1380.278,
  '28': 1399.851,
  '29': 1424.5,
  '30': 1443.889,
  '31': 1466.833,
  '32': 1488.077,
  '33': 1510.444,
  '34': 1535.5,
  '35': 1554.444,
  '36': 1577.778,
  '37': 1597.0,
  '38': 1618.067,
  '39': 1640.5,
  '40': 1665.0,
  '41': 1687.417,
  '42': 1711.833,
  '43': 1732.083,
  '44': 1750.0,
  '45': 1770.658,
  '46': 1793.889,
  '47': 1811.603,
  '48': 1834.889,
  '49': 1854.889,
  '50': 1883.667,
  '51': 1907.083,
  '52': 1931.0,
  '53': 1956.167,
  '54': 1973.833,
  '55': 2001.341,
  '56': 2025.278,
  '57': 2049.484,
  '58': 2072.465,
  '59': 2096.9,
  '60': 2123.555,
  '61': 2146.833,
  '62': 2171.2,
  '63': 2203.0,
  '64': 2226.825,
  '65': 2257.833,
  '66': 2283.188,
  '67': 2310.555,
  '68': 2344.286,
  '69': 2377.963,
  '70': 2412.167,
  '71': 2446.792,
  '72': 2483.278,
  '73': 2515.0,
  '74': 2545.208,
  '75': 2594.087,
  '76': 2635.0,
  '77': 2685.667,
  '78': 2721.5,
  '79': 2761.111,
  '80': 2810.917,
  '81': 2858.667,
  '82': 2905.583,
  '83': 2950.333,
  '84': 3009.484,
  '85': 3071.667,
  '86': 3142.667,
  '87': 3226.667,
  '88': 3318.222,
  '89': 3396.833,
  '90': 3513.444,
  '91': 3621.722,
  '92': 3765.75,
  '93': 3936.167,
  '94': 4110.222,
  '95': 4351.167,
  '96': 4642.444,
  '97': 5092.753,
  '98': 5873.333,
  '99': 8486.625,

};

export function getPercentileNetIncomeMonthly(userNetIncomeMonthly: number, age: number|false = false): number {
  let datum:Record<string, number> = netIncomeMonthly;
  if (age !== false) {
    let ageGroup: number = age - (age % 5);
    while (!(ageGroup in netIncomeMonthlyByAge) || ageGroup < 0) {
      ageGroup -= 5;
      if (ageGroup < 0) {
        ageGroup = 0;
      }
    }
    datum = netIncomeMonthlyByAge[ageGroup];
  }
  for (const datumKey of Object.keys(datum).reverse()) {
    if (userNetIncomeMonthly > datum[datumKey]) {
      return parseInt(datumKey, 10);
    }
  }
  return 0;
}
