
























































































import {Component, Vue} from 'vue-property-decorator';

import NetIncomeDisplay from "@/components/NetIncomeDisplay.vue";
import NetWorthDisplay from "@/components/NetWorthDisplay.vue";
import {getPercentileNetIncomeMonthly} from "@/data/netIncomeMonthly";

@Component({
  components: {
    NetWorthDisplay,
    NetIncomeDisplay,
  }
})
export default class HelloWorld extends Vue {
  get monthlyLivingCost(): number {
    if (!this.localStorage.netIncomeMonthly) {
      return 0;
    }
    return this.localStorage.netIncomeMonthly - this.localStorage.savingMonthly;
  }

  get savingsRate(): number {
    if (!this.localStorage.netIncomeMonthly) {
      return 0;
    }
    return 100 / this.localStorage.netIncomeMonthly * this.localStorage.savingMonthly;
  }

  get livingCostPercentile(): number {
    return getPercentileNetIncomeMonthly(this.monthlyLivingCost);
  }

  get fireNumber(): number {
    return this.monthlyLivingCost / 0.04 * 12;
  }

  get inOneYear(): number {
    return this.savingsRate * 12 + this.localStorage.invested * 1.07;
  }
  get inTwoYears(): number {
    return this.savingsRate * 12 + this.inOneYear * 1.07;
  }
  get graphData(): number[] {
    let runningValue = this.localStorage.invested;
    let runningFireNumber = this.fireNumber;
    const resultArray = [
      runningValue,
    ];
    while (runningValue < runningFireNumber){
      runningValue = this.localStorage.savingMonthly * 12 + runningValue * 1.07;
      runningFireNumber *= 1.02;
      resultArray.push(runningValue);
    }
    return resultArray;
  }
}
